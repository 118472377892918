.App {
  text-align: center;
}

.App-header {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.logo {
  width: 400px;
  max-width: 80%;
}

.App-link {
  color: #61dafb;
}

.titleAccordion{
  background-color: #252530 !important;
  color: white !important;
}
.bodyAccordion{
  background-color: #D6DEEB;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
